/* You can add global styles to this file, and also import other style files */
/* NU adaugati stiluri pentru 1 modul specific aici, adaugati in acel modul */
/* NU adaugati multe stiluri aici folositi fisiere separate in folderul styles */
/* Modificari pt temele bootstrap se fac in folderul styles si se modifica angular.json */

/* Daca adaugati stiluri pentru mai multe module folositi prefix/identificatori clari */
/* Aici adaugati doar stiluri globale/general valabile pt toata aplicatia */

// stiluri pt angular.json 
// "node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss",
// "node_modules/@fortawesome/fontawesome-pro/scss/regular.scss",
// "node_modules/@fortawesome/fontawesome-pro/scss/light.scss",
// "node_modules/@fortawesome/fontawesome-pro/scss/solid.scss",
// "node_modules/@fortawesome/fontawesome-pro/scss/brands.scss",
// "node_modules/ag-grid-community/dist/styles/ag-grid.min.css",

// bootswatch 6
// { "input": "node_modules/bootswatch/dist/zephyr/bootstrap.min.css", "bundleName": "theme.zephyr", "inject": false },
// { "input": "node_modules/bootswatch/dist/vapor/bootstrap.min.css", "bundleName": "theme.vapor", "inject": false },
// { "input": "node_modules/bootswatch/dist/quartz/bootstrap.min.css", "bundleName": "theme.quartz", "inject": false },
// { "input": "node_modules/bootswatch/dist/morph/bootstrap.min.css", "bundleName": "theme.morph", "inject": false },     
// ...         
      
@media (min-width: 768px) {
  .form-horizontal .row .form-label,
  .form-horizontal .row .col-form-label {
    text-align: right;
  }
}

input:invalid,
textarea:invalid,
select:invalid {
  border: red solid 1px !important;
  border-left: 5px solid red !important;
}

.bold {
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}

// grid
.ag-theme-alpine .ag-ltr .ag-cell,
.ag-theme-balham .ag-ltr .ag-cell {
  border-right: 1px solid darkgrey ;
}
.ag-theme-alpine-dark .ag-ltr .ag-cell,
.ag-theme-balham-dark .ag-ltr .ag-cell {
  border-right: 1px solid darkgrey ;
}
.cell-center .ag-cell-wrapper {
  justify-content: center;
}
.form-control.typeahead-caret {
  padding-right: calc(1.5em + 0.5000rem);
  background-image: url('./assets/caret_down_512px.png');
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.5000em + 0.375rem) calc(0.5000em + 0.375rem);
}

.toast-w400 {
  width: 400px;
}

#toast-container .ngx-toastr { 
  max-width: 400px; 
  min-width: 300px; 
  width: 100%;
}